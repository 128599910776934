<template>
  <div>
    <v-app>
      <v-container>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12">
            <v-tabs grow v-model="tab">
              <v-tab href="#merkezdetaylari"> Merkez detayları </v-tab>
              <v-tab href="#merkezarastirmacilari">
                Merkez Araştırmacıları
              </v-tab>
            </v-tabs>
            <v-tabs-items :value="tab">
              <v-tab-item value="merkezdetaylari">
                <v-row>
                  <v-col md="6">
                    <div class="form-group">
                      <v-text-field
                        required
                        v-model="selected.centerName"
                        label="Merkez Adı"
                        filled
                        dense
                        disabled
                      ></v-text-field>
                    </div>
                    <div class="form-group">
                      <v-select
                        label="Bölümü Seçiniz..."
                        v-model="selected.department"
                        filled
                        dense
                        disabled
                        :items="specialtyDepartmentitems"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                    </div>
                    <div class="form-group">
                      <v-select
                        :items="titleitems"
                        label=" Merkez Tipi Seçiniz.."
                        v-model="selected.centerType"
                        filled
                        dense
                        disabled
                      ></v-select>
                    </div>
                    <div class="form-group">
                      <v-select
                        label="Ülke Seçiniz..."
                        v-model="selected.country"
                        filled
                        dense
                        disabled
                        :items="countryitems"
                      ></v-select>
                    </div>
                    <div class="form-group">
                      <v-select
                        label="İl Seçiniz..."
                        v-model="selected.city"
                        filled
                        dense
                        disabled
                        :items="cityitems"
                      ></v-select>
                    </div>
                  </v-col>
                  <v-col md="6">
                    <div class="form-group">
                      <v-text-field
                        required
                        v-model="selected.landPhone"
                        label="Sabit Telefon"
                        filled
                        dense
                        disabled
                      ></v-text-field>
                    </div>

                    <div class="form-group">
                      <v-text-field
                        required
                        v-model="selected.email"
                        label="Mail"
                        filled
                        dense
                        disabled
                      ></v-text-field>
                    </div>

                    <div class="form-group">
                      <v-text-field
                        required
                        v-model="selected.webAddress"
                        label="Web adresi"
                        filled
                        dense
                        disabled
                      ></v-text-field>
                    </div>

                    <div class="form-group">
                      <v-select
                        label="İlçe Seçiniz..."
                        v-model="selected.district"
                        filled
                        dense
                        disabled
                        :items="districtitems"
                      ></v-select>
                    </div>
                    <div>
                      <v-text-field
                        required
                        v-model="selected.postCode"
                        label="Posta Kodu"
                        filled
                        dense
                        disabled
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col>
                    <div class="form-group">
                      <v-textarea
                        required
                        v-model="selected.address"
                        label="Açık Adres"
                        filled
                        dense
                        disabled
                      ></v-textarea>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="merkezarastirmacilari">
                <v-row>
                  <v-col md="4">
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Araştırmacı Ara"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="(globaladmin||cro) ? headers:headers2"
                      :items="centerResearchers"
                      :search="search"
                      :loading="loadings.table"
                      loading-text="Yükleniyor..."
                      :footer-props="{
                        'items-per-page-options': [20, 30, 40, -1],
                      }"
                      :items-per-page="20"
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
//import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
export default {
  name: "centersview",
  data() {
    return {
        globaladmin: false,
      cro: false,
      user: false,
      objeler: {
        id: "",
      },
      loadings: {
        table: false,
      },
      search: "",
      centerResearchers: [],
      headers: [
        {
          text: "Adı",
          align: "left",
          value: "name",
        },
        { text: "Soyadı", value: "surname" },
        { text: "Kullanıcı Adı", value: "username" },
        { text: "Ünvanı", value: "title" },
        { text: "Merkez/Organizasyon", value: "center.centerName" },
        { text: "Bölüm", value: "specialtyDepartment" },
        { text: "E-posta", value: "email" },
        { text: "Telefon", value: "phoneNumber" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
      ],
          headers2: [
        {
          text: "Adı",
          align: "left",
          value: "name",
        },
        { text: "Soyadı", value: "surname" },      
        { text: "Ünvanı", value: "title" },    
        { text: "Bölüm", value: "specialtyDepartment" },
        { text: "E-posta", value: "email" },
        { text: "Telefon", value: "phoneNumber" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
      ],
      currentid: null,
      selected: {
        centerName: "",
        department: "",
        centerType: "",
        landPhone: "",
        email: "",
        webAddress: "",
        country: "",
        city: "",
        district: "",
        postCode: "",
        address: "",
      },
      titleitems: [
        { text: "Özel Hastahane", value: "Özel Hastahane" },
        {
          text: "Eğitim Araştırma Hastahanesi",
          value: "Eğitim Araştırma Hastahanesi",
        },
        { text: "Üniversite Hastahanesi", value: "Üniversite Hastahanesi" },
      ],
      countryitems: [],
      cityitems: [],
      districtitems: [],
      specialtyDepartmentitems: [
        { text: "Kardiyoloji", value: "Kardiyoloji" },
        { text: "Kardiyoloji ABD", value: "Kardiyoloji ABD" },
        { text: "Nefroloji", value: "Nefroloji" },
        { text: "Diğer", value: "Diğer" },
      ],
      selectedPageItemName: "",
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
     this.globaladmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
    console.log("page", selectedPage);
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Merkez Görüntüleme" }]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/centerlist" },
        { title: "Merkez Görüntüleme" },
      ]);
    }
    var temp = localStorage.getItem("itemler");
    this.objeler = JSON.parse(temp);
    this.currentid = this.objeler.id;
    this.getCurrentCentersinfo();
  },
  methods: {
    getCurrentCentersinfo() {
      ApiService.get("/center", this.currentid)
        .then(({ data }) => {
          console.log("Merkez bilgileri geldi", data);

          this.selected = data.result;
          this.centerResearchers = this.selected.users;
          console.log("selected ", this.selected);
          this.countryitems.push({
            text: this.selected.country,
            value: this.selected.country,
          });
          this.cityitems.push({
            text: this.selected.city,
            value: this.selected.city,
          });
          this.districtitems.push({
            text: this.selected.district,
            value: this.selected.district,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
        ...mapGetters([
      "currentUserPersonalInfo",
      "currentUser",
      "isGlobalAdmin",
      "isCro",
      "isNormalUser",
    ]),
  },
};
</script>
